import React, { useEffect, useState } from "react";

const TestPage: React.FC = () => {
  const [data, SetData] = useState<string[]>([]);

  useEffect(() => {
    SetData(["edwin", "admin"]);
  }, []);

  return (
    <div>
      <h2>Names</h2>
      <ul>
        {data.map((name, index) => (
          <li key={index}>{name}</li>
        ))}
      </ul>
    </div>
  );
};

export default TestPage;
