import ConversationContainer from "./../conversations/ConversationContainer";
import ConversationsContainer from "./../conversations/ConversationsContainer";
import stylesheet from "../../styles";
import { Box } from "@twilio-paste/core";
import { ReduxConversation } from "./../../store/reducers/convoReducer";
import { Client } from "@twilio/conversations";

type ChatPageProps = {
  openedConversation?: ReduxConversation;
  client?: Client;
};

const ChatPage: React.FC<ChatPageProps> = ({ openedConversation, client }) => {
  return (
    <>
      <ConversationsContainer client={client} />
      <Box style={stylesheet.messagesWrapper}>
        <ConversationContainer
          conversation={openedConversation}
          client={client}
        />
      </Box>
    </>
  );
};

export default ChatPage;
