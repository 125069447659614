import React, { useEffect, useState } from "react";

// Definir los tipos de los datos
interface Customer {
  id: string | null;
  accountId: string | null;
  customerNumber: string | null;
  name: string | null;
  serviceAddress: string | null;
  serviceCity: string | null;
  serviceState: string | null;
  serviceZipCode: string | null;
  billingAddress: string | null;
  billingCity: string | null;
  billingState: string | null;
  billingZipCode: string | null;
  fullName: string | null;
  updatedAt: string | null;
  serviceCountry: string | null;
  billingCountry: string | null;
  whichBillingAddress: string | null;
}

// Función de filtro global
const GlobalFilter = ({
  globalFilter,
  setGlobalFilter,
}: {
  globalFilter: string;
  setGlobalFilter: (value: string | undefined) => void;
}) => {
  return (
    <input
      value={globalFilter || ""}
      onChange={(e) => setGlobalFilter(e.target.value || undefined)}
      placeholder="Search all columns..."
      style={{ marginBottom: "10px", padding: "5px" }}
    />
  );
};

const KickservPage: React.FC = () => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState("");

  const parseCustomerData = (xmlText: any) => {
    try {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlText, "application/xml");
      const customerNodes = xmlDoc.getElementsByTagName("customer");

      const customersArray = Array.from(customerNodes).map((customerNode) => {
        const customer: Customer = {
          id: customerNode.getElementsByTagName("id")[0]?.textContent || null,
          accountId:
            customerNode.getElementsByTagName("account-id")[0]?.textContent ||
            null,
          customerNumber:
            customerNode.getElementsByTagName("customer-number")[0]
              ?.textContent || null,
          name:
            customerNode.getElementsByTagName("name")[0]?.textContent || null,
          serviceAddress:
            customerNode.getElementsByTagName("service-address")[0]
              ?.textContent || null,
          serviceCity:
            customerNode.getElementsByTagName("service-city")[0]?.textContent ||
            null,
          serviceState:
            customerNode.getElementsByTagName("service-state")[0]
              ?.textContent || null,
          serviceZipCode:
            customerNode.getElementsByTagName("service-zip-code")[0]
              ?.textContent || null,
          billingAddress:
            customerNode.getElementsByTagName("billing-address")[0]
              ?.textContent || null,
          billingCity:
            customerNode.getElementsByTagName("billing-city")[0]?.textContent ||
            null,
          billingState:
            customerNode.getElementsByTagName("billing-state")[0]
              ?.textContent || null,
          billingZipCode:
            customerNode.getElementsByTagName("billing-zip-code")[0]
              ?.textContent || null,
          fullName:
            customerNode.getElementsByTagName("full-name")[0]?.textContent ||
            null,
          updatedAt:
            customerNode.getElementsByTagName("updated-at")[0]?.textContent ||
            null,
          serviceCountry:
            customerNode.getElementsByTagName("service-country")[0]
              ?.textContent || null,
          billingCountry:
            customerNode.getElementsByTagName("billing-country")[0]
              ?.textContent || null,
          whichBillingAddress:
            customerNode.getElementsByTagName("which-billing-address")[0]
              ?.textContent || null,
        };
        return customer;
      });

      setCustomers(customersArray);
    } catch (error) {
      console.error("Error fetching and parsing XML", error);
    }
  };

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/xml");
    myHeaders.append("authorization", process.env.KICKSERV_TOKEN as string);
    /*myHeaders.append(
      "Cookie",
      "_kickserv_secure_session=b3FwbW9kWTR1em0zUkdrYVVwWnYxL3Y3cjFTTmRnOHFDbXIxWkhZOURIOVJYSHkvRTVCWnF0SmRaTzZxeGxvL3lyYUxsOXlocUw5bHlLQ2pya1d3V29MK3prYU93Kzk3S2tZUUpHaUxJQlk1SHBkSTJLS1NIYkR0QW0wUXo4L0JQSXQ0RHJrUU04RkhNbWN5YWdmSk5SQmJSdEpyeGlnQnRvdUUrUVYvOWRMT0hBK0xMdWN3aDJCMFN5VXlBZjFFcTNIWUpjVW1sUWc1cm1QRjN4eUUxK2xmTUhQVXFCcGJ3L0xpdXVLd2gvMCtEcHFUZWJSVm12dmRSMzJ2TmxOOVJHZldqdUVwQi9hZ0JOdmk1aUtmR1JET05qczBsZ3VBK0VTczlTQ3pRZUU9LS1CUUwzeE9DKzNJUEFTMWxycGNzL1lBPT0%3D--2d188de22bc56671f4e05a6badb8865e27eb5117; kickserv_session=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJraWNrc2Vydi1yYWlscy1hcHAiLCJleHAiOjE3MjYxMjA1NDQsInVzZXIiOnsiaWQiOjQ0NDI1OSwiZW1wbG95ZWVfbnVtYmVyIjoxLCJuYW1lIjoiTWF0dCBHZXJjaG93IiwiYWNjb3VudF9zbHVnIjoiMmE2YzA2IiwidHlwZSI6ImVtcGxveWVlIn19.8wku3v-1WJ9NWfyUxSQdHY7LtgL6ijYDhFCa5jOtpVQUd__0KjEiVwpA-LuVe53Hz-kV6JpCSqYGuROMpiEwZd4G7UI8XZFQtfcJoFITSwAkXzPL3uEfAs2SMjo7q84RQv2KKJBUMcJybDhEARyEiMWWZEX_3MP-eEUpaRvkfkzXkRo5ue7-KLSUVFOahaubCt6LN80bah_gIHRVKrxthracPTsK5981JLWZHP34QIZhxTfdi99wWMN_L_itm8fwaDE2zX3odt9qyCMA5NINynvYKAIXRMm-lzEMigHORmbBRJKdM4mQD4SDCOfupXAJpXgGTPwwoJtDWq-kCo0Gaw"
    );*/

    const requestOptions: RequestInit = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow" as RequestRedirect,
    };

    fetch(
      "https://app.kickserv.com/" +
        (process.env.REACT_APP_KICKSERV_ACCOUNT_SLUG as string) +
        "/customers.xml",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        //setData(result);
      })
      .catch((error) => console.error(error));

    const customersXML = `
<customers type="array">
    <customer>
        <id type="integer">15690762</id>
        <account-id type="integer">257117</account-id>
        <customer-number type="integer">1</customer-number>
        <origin>manual</origin>
        <created-at type="dateTime">2024-01-11T17:47:44-05:00</created-at>
        <name>Henry Gale</name>
        <phone-number nil="true"/>
        <fax nil="true"/>
        <service-address>104 Soule Street</service-address>
        <service-city>Dodge City</service-city>
        <service-state>KS</service-state>
        <service-zip-code>67801</service-zip-code>
        <billing-address>104 Soule Street</billing-address>
        <billing-city>Dodge City</billing-city>
        <billing-state>KS</billing-state>
        <billing-zip-code>67801</billing-zip-code>
        <special-instructions nil="true"/>
        <email-address nil="true"/>
        <alt-phone-number nil="true"/>
        <parent-id type="integer" nil="true"/>
        <password-digest nil="true"/>
        <list-id nil="true"/>
        <time-created type="dateTime" nil="true"/>
        <time-modified type="dateTime" nil="true"/>
        <edit-sequence nil="true"/>
        <full-name>Henry Gale</full-name>
        <is-active type="boolean">true</is-active>
        <parent-ref-list-id nil="true"/>
        <sublevel type="integer" nil="true"/>
        <company-name>Henry Gale</company-name>
        <first-name nil="true"/>
        <last-name nil="true"/>
        <mobile nil="true"/>
        <email nil="true"/>
        <contact nil="true"/>
        <alt-contact nil="true"/>
        <customer-type-ref-full-name nil="true"/>
        <terms-ref-full-name nil="true"/>
        <sales-rep-ref-full-name nil="true"/>
        <balance type="decimal" nil="true"/>
        <total-balance type="decimal" nil="true"/>
        <sales-tax-code-ref-full-name nil="true"/>
        <item-sales-tax-ref-full-name nil="true"/>
        <resale-number nil="true"/>
        <account-number nil="true"/>
        <credit-limit type="decimal" nil="true"/>
        <preferred-payment-method-ref-full-name nil="true"/>
        <job-status nil="true"/>
        <job-start-date type="dateTime" nil="true"/>
        <job-projected-end-date type="dateTime" nil="true"/>
        <job-end-date type="dateTime" nil="true"/>
        <job-desc nil="true"/>
        <job-type-ref-full-name nil="true"/>
        <is-statement-with-parent type="boolean" nil="true"/>
        <delivery-method nil="true"/>
        <price-level-ref-full-name nil="true"/>
        <custom-text1 nil="true"/>
        <custom-number1 type="decimal" nil="true"/>
        <custom-datetime1 type="dateTime" nil="true"/>
        <custom-text2 nil="true"/>
        <custom-number2 type="decimal" nil="true"/>
        <custom-datetime2 type="dateTime" nil="true"/>
        <custom-text3 nil="true"/>
        <custom-number3 type="decimal" nil="true"/>
        <custom-datetime3 type="dateTime" nil="true"/>
        <custom-text4 nil="true"/>
        <custom-number4 type="decimal" nil="true"/>
        <custom-datetime4 type="dateTime" nil="true"/>
        <custom-text5 nil="true"/>
        <custom-number5 type="decimal" nil="true"/>
        <custom-datetime5 type="dateTime" nil="true"/>
        <custom-text6 nil="true"/>
        <custom-number6 type="decimal" nil="true"/>
        <custom-datetime6 type="dateTime" nil="true"/>
        <custom-text7 nil="true"/>
        <custom-number7 type="decimal" nil="true"/>
        <custom-datetime7 type="dateTime" nil="true"/>
        <custom-text8 nil="true"/>
        <custom-number8 type="decimal" nil="true"/>
        <custom-datetime8 type="dateTime" nil="true"/>
        <custom-text9 nil="true"/>
        <custom-number9 type="decimal" nil="true"/>
        <custom-datetime9 type="dateTime" nil="true"/>
        <custom-text10 nil="true"/>
        <custom-number10 type="decimal" nil="true"/>
        <custom-datetime10 type="dateTime" nil="true"/>
        <updated-at type="dateTime">2024-01-11T17:47:44-05:00</updated-at>
        <service-country>United States</service-country>
        <billing-country>United States</billing-country>
        <sales-tax-code-ref-list-id nil="true"/>
        <item-sales-tax-ref-list-id nil="true"/>
        <custom-text11 nil="true"/>
        <custom-text12 nil="true"/>
        <custom-text13 nil="true"/>
        <custom-text14 nil="true"/>
        <custom-text15 nil="true"/>
        <custom-text16 nil="true"/>
        <custom-text17 nil="true"/>
        <custom-text18 nil="true"/>
        <custom-text19 nil="true"/>
        <custom-text20 nil="true"/>
        <created-by type="integer" nil="true"/>
        <updated-by type="integer" nil="true"/>
        <tax-id type="integer" nil="true"/>
        <which-billing-address>service</which-billing-address>
        <customer-type-id type="integer" nil="true"/>
        <customer-source-id type="integer" nil="true"/>
        <import-id type="integer" nil="true"/>
        <stripe-customer-id nil="true"/>
        <token>6uGxutzq1GmMG617zWtc2K3CTEloYfl9L_i2qCI0K6U</token>
        <remember-me-token nil="true"/>
        <latitude type="float">37.7728419</latitude>
        <longitude type="float">-100.0174915</longitude>
        <company type="boolean">false</company>
        <deleted-at type="dateTime" nil="true"/>
        <qb-error nil="true"/>
        <send-reminders type="boolean">true</send-reminders>
        <notification-email-address nil="true"/>
        <notification-phone-number>+12125551717</notification-phone-number>
        <notify-via-email type="boolean">true</notify-via-email>
        <notify-via-sms type="boolean">false</notify-via-sms>
        <send-notifications type="boolean">true</send-notifications>
        <notification-email nil="true"/>
        <sales-terms-ref-id nil="true"/>
        <sales-tax-code-id type="integer" nil="true"/>
        <service-address-2 nil="true"/>
        <billing-address-2 nil="true"/>
        <last-job-completed-number type="integer" nil="true"/>
        <last-job-completed-at type="dateTime" nil="true"/>
        <last-job-completed-name nil="true"/>
        <place-id>ChIJzyDoOaMbp4cR_kvKSXPAt74</place-id>
        <place-id-retrieved-at type="dateTime">2024-01-11T17:47:44-05:00</place-id-retrieved-at>
        <custom-fields-migrated type="boolean" nil="true"/>
        <opt-out-reason nil="true"/>
        <email-bounced type="boolean" nil="true"/>
        <street-latitude type="float" nil="true"/>
        <street-longitude type="float" nil="true"/>
        <stripe-payment-method-id nil="true"/>
        <children type="array">
            <child type="Customer">
                <id type="integer">15690763</id>
                <account-id type="integer">257117</account-id>
                <customer-number type="integer">2</customer-number>
                <origin>manual</origin>
                <created-at type="dateTime">2024-01-11T17:47:45-05:00</created-at>
                <name>Dorothy Gale</name>
                <phone-number nil="true"/>
                <fax nil="true"/>
                <service-address>2501 North 14th Avenue</service-address>
                <service-city>Dodge City</service-city>
                <service-state>KS</service-state>
                <service-zip-code>67801</service-zip-code>
                <billing-address>104 Soule Street</billing-address>
                <billing-city>Dodge City</billing-city>
                <billing-state>KS</billing-state>
                <billing-zip-code>67801</billing-zip-code>
                <special-instructions>Her little dog barks but is very friendly.</special-instructions>
                <email-address></email-address>
                <alt-phone-number nil="true"/>
                <parent-id type="integer">15690762</parent-id>
                <password-digest nil="true"/>
                <list-id nil="true"/>
                <time-created type="dateTime" nil="true"/>
                <time-modified type="dateTime" nil="true"/>
                <edit-sequence nil="true"/>
                <full-name>Dorothy Gale</full-name>
                <is-active type="boolean">true</is-active>
                <parent-ref-list-id nil="true"/>
                <sublevel type="integer" nil="true"/>
                <company-name>Dorothy Gale</company-name>
                <first-name nil="true"/>
                <last-name nil="true"/>
                <mobile nil="true"/>
                <email></email>
                <contact nil="true"/>
                <alt-contact nil="true"/>
                <customer-type-ref-full-name nil="true"/>
                <terms-ref-full-name nil="true"/>
                <sales-rep-ref-full-name nil="true"/>
                <balance type="decimal" nil="true"/>
                <total-balance type="decimal" nil="true"/>
                <sales-tax-code-ref-full-name nil="true"/>
                <item-sales-tax-ref-full-name nil="true"/>
                <resale-number nil="true"/>
                <account-number nil="true"/>
                <credit-limit type="decimal" nil="true"/>
                <preferred-payment-method-ref-full-name nil="true"/>
                <job-status nil="true"/>
                <job-start-date type="dateTime" nil="true"/>
                <job-projected-end-date type="dateTime" nil="true"/>
                <job-end-date type="dateTime" nil="true"/>
                <job-desc nil="true"/>
                <job-type-ref-full-name nil="true"/>
                <is-statement-with-parent type="boolean" nil="true"/>
                <delivery-method nil="true"/>
                <price-level-ref-full-name nil="true"/>
                <custom-text1 nil="true"/>
                <custom-number1 type="decimal" nil="true"/>
                <custom-datetime1 type="dateTime" nil="true"/>
                <custom-text2 nil="true"/>
                <custom-number2 type="decimal" nil="true"/>
                <custom-datetime2 type="dateTime" nil="true"/>
                <custom-text3 nil="true"/>
                <custom-number3 type="decimal" nil="true"/>
                <custom-datetime3 type="dateTime" nil="true"/>
                <custom-text4 nil="true"/>
                <custom-number4 type="decimal" nil="true"/>
                <custom-datetime4 type="dateTime" nil="true"/>
                <custom-text5 nil="true"/>
                <custom-number5 type="decimal" nil="true"/>
                <custom-datetime5 type="dateTime" nil="true"/>
                <custom-text6 nil="true"/>
                <custom-number6 type="decimal" nil="true"/>
                <custom-datetime6 type="dateTime" nil="true"/>
                <custom-text7 nil="true"/>
                <custom-number7 type="decimal" nil="true"/>
                <custom-datetime7 type="dateTime" nil="true"/>
                <custom-text8 nil="true"/>
                <custom-number8 type="decimal" nil="true"/>
                <custom-datetime8 type="dateTime" nil="true"/>
                <custom-text9 nil="true"/>
                <custom-number9 type="decimal" nil="true"/>
                <custom-datetime9 type="dateTime" nil="true"/>
                <custom-text10 nil="true"/>
                <custom-number10 type="decimal" nil="true"/>
                <custom-datetime10 type="dateTime" nil="true"/>
                <updated-at type="dateTime">2024-01-11T17:47:45-05:00</updated-at>
                <service-country>United States</service-country>
                <billing-country>United States</billing-country>
                <sales-tax-code-ref-list-id nil="true"/>
                <item-sales-tax-ref-list-id nil="true"/>
                <custom-text11 nil="true"/>
                <custom-text12 nil="true"/>
                <custom-text13 nil="true"/>
                <custom-text14 nil="true"/>
                <custom-text15 nil="true"/>
                <custom-text16 nil="true"/>
                <custom-text17 nil="true"/>
                <custom-text18 nil="true"/>
                <custom-text19 nil="true"/>
                <custom-text20 nil="true"/>
                <created-by type="integer" nil="true"/>
                <updated-by type="integer" nil="true"/>
                <tax-id type="integer" nil="true"/>
                <which-billing-address>parent</which-billing-address>
                <customer-type-id type="integer" nil="true"/>
                <customer-source-id type="integer" nil="true"/>
                <import-id type="integer" nil="true"/>
                <stripe-customer-id nil="true"/>
                <token>QriPVsi6qL8pgeLBG2rVYqKDK1RcovK2LEOj0-ZiW2Q</token>
                <remember-me-token nil="true"/>
                <latitude type="float">37.7757963</latitude>
                <longitude type="float">-100.0373646</longitude>
                <company type="boolean">false</company>
                <deleted-at type="dateTime" nil="true"/>
                <qb-error nil="true"/>
                <send-reminders type="boolean">true</send-reminders>
                <notification-email-address></notification-email-address>
                <notification-phone-number>+18035552000</notification-phone-number>
                <notify-via-email type="boolean">true</notify-via-email>
                <notify-via-sms type="boolean">false</notify-via-sms>
                <send-notifications type="boolean">true</send-notifications>
                <notification-email nil="true"/>
                <sales-terms-ref-id nil="true"/>
                <sales-tax-code-id type="integer" nil="true"/>
                <service-address-2 nil="true"/>
                <billing-address-2 nil="true"/>
                <last-job-completed-number type="integer" nil="true"/>
                <last-job-completed-at type="dateTime" nil="true"/>
                <last-job-completed-name nil="true"/>
                <place-id>ChIJz57fmUwap4cRx9lEwhWs7WI</place-id>
                <place-id-retrieved-at type="dateTime">2024-01-11T17:47:45-05:00</place-id-retrieved-at>
                <custom-fields-migrated type="boolean">false</custom-fields-migrated>
                <opt-out-reason>Destination number unknown</opt-out-reason>
                <email-bounced type="boolean" nil="true"/>
                <street-latitude type="float" nil="true"/>
                <street-longitude type="float" nil="true"/>
                <stripe-payment-method-id nil="true"/>
            </child>
        </children>
    </customer><customer>
        <id type="integer">15696165</id>
        <account-id type="integer">257117</account-id>
        <customer-number type="integer">31</customer-number>
        <origin>CSV Import</origin>
        <created-at type="dateTime">2024-01-15T14:41:00-05:00</created-at>
        <name>Keith Phelps</name>
        <phone-number>ph: +1 (314) 603-3786</phone-number>
        <fax nil="true"/>
        <service-address nil="true"/>
        <service-city nil="true"/>
        <service-state nil="true"/>
        <service-zip-code>65441</service-zip-code>
        <billing-address nil="true"/>
        <billing-city nil="true"/>
        <billing-state nil="true"/>
        <billing-zip-code>65441</billing-zip-code>
        <special-instructions nil="true"/>
        <email-address>vickiphelps1973@gmail.com</email-address>
        <alt-phone-number nil="true"/>
        <parent-id type="integer" nil="true"/>
        <password-digest nil="true"/>
        <list-id nil="true"/>
        <time-created type="dateTime" nil="true"/>
        <time-modified type="dateTime" nil="true"/>
        <edit-sequence nil="true"/>
        <full-name>Keith Phelps</full-name>
        <is-active type="boolean">true</is-active>
        <parent-ref-list-id nil="true"/>
        <sublevel type="integer" nil="true"/>
        <company-name>Keith Phelps</company-name>
        <first-name nil="true"/>
        <last-name nil="true"/>
        <mobile nil="true"/>
        <email>vickiphelps1973@gmail.com</email>
        <contact nil="true"/>
        <alt-contact nil="true"/>
        <customer-type-ref-full-name nil="true"/>
        <terms-ref-full-name nil="true"/>
        <sales-rep-ref-full-name nil="true"/>
        <balance type="decimal" nil="true"/>
        <total-balance type="decimal" nil="true"/>
        <sales-tax-code-ref-full-name nil="true"/>
        <item-sales-tax-ref-full-name nil="true"/>
        <resale-number nil="true"/>
        <account-number nil="true"/>
        <credit-limit type="decimal" nil="true"/>
        <preferred-payment-method-ref-full-name nil="true"/>
        <job-status nil="true"/>
        <job-start-date type="dateTime" nil="true"/>
        <job-projected-end-date type="dateTime" nil="true"/>
        <job-end-date type="dateTime" nil="true"/>
        <job-desc nil="true"/>
        <job-type-ref-full-name nil="true"/>
        <is-statement-with-parent type="boolean" nil="true"/>
        <delivery-method nil="true"/>
        <price-level-ref-full-name nil="true"/>
        <custom-text1 nil="true"/>
        <custom-number1 type="decimal" nil="true"/>
        <custom-datetime1 type="dateTime" nil="true"/>
        <custom-text2 nil="true"/>
        <custom-number2 type="decimal" nil="true"/>
        <custom-datetime2 type="dateTime" nil="true"/>
        <custom-text3 nil="true"/>
        <custom-number3 type="decimal" nil="true"/>
        <custom-datetime3 type="dateTime" nil="true"/>
        <custom-text4 nil="true"/>
        <custom-number4 type="decimal" nil="true"/>
        <custom-datetime4 type="dateTime" nil="true"/>
        <custom-text5 nil="true"/>
        <custom-number5 type="decimal" nil="true"/>
        <custom-datetime5 type="dateTime" nil="true"/>
        <custom-text6 nil="true"/>
        <custom-number6 type="decimal" nil="true"/>
        <custom-datetime6 type="dateTime" nil="true"/>
        <custom-text7 nil="true"/>
        <custom-number7 type="decimal" nil="true"/>
        <custom-datetime7 type="dateTime" nil="true"/>
        <custom-text8 nil="true"/>
        <custom-number8 type="decimal" nil="true"/>
        <custom-datetime8 type="dateTime" nil="true"/>
        <custom-text9 nil="true"/>
        <custom-number9 type="decimal" nil="true"/>
        <custom-datetime9 type="dateTime" nil="true"/>
        <custom-text10 nil="true"/>
        <custom-number10 type="decimal" nil="true"/>
        <custom-datetime10 type="dateTime" nil="true"/>
        <updated-at type="dateTime">2024-01-15T14:41:00-05:00</updated-at>
        <service-country nil="true"/>
        <billing-country nil="true"/>
        <sales-tax-code-ref-list-id nil="true"/>
        <item-sales-tax-ref-list-id nil="true"/>
        <custom-text11 nil="true"/>
        <custom-text12 nil="true"/>
        <custom-text13 nil="true"/>
        <custom-text14 nil="true"/>
        <custom-text15 nil="true"/>
        <custom-text16 nil="true"/>
        <custom-text17 nil="true"/>
        <custom-text18 nil="true"/>
        <custom-text19 nil="true"/>
        <custom-text20 nil="true"/>
        <created-by type="integer" nil="true"/>
        <updated-by type="integer" nil="true"/>
        <tax-id type="integer" nil="true"/>
        <which-billing-address>service</which-billing-address>
        <customer-type-id type="integer">235276</customer-type-id>
        <customer-source-id type="integer" nil="true"/>
        <import-id type="integer" nil="true"/>
        <stripe-customer-id nil="true"/>
        <token>ovz4AKLYhl0Vk9yuQNKzE2ualbcuTTHwtivfOYrjMWg</token>
        <remember-me-token nil="true"/>
        <latitude type="float">38.0786535</latitude>
        <longitude type="float">-91.15449629999999</longitude>
        <company type="boolean">false</company>
        <deleted-at type="dateTime" nil="true"/>
        <qb-error nil="true"/>
        <send-reminders type="boolean">true</send-reminders>
        <notification-email-address>vickiphelps1973@gmail.com</notification-email-address>
        <notification-phone-number nil="true"/>
        <notify-via-email type="boolean">true</notify-via-email>
        <notify-via-sms type="boolean">false</notify-via-sms>
        <send-notifications type="boolean">true</send-notifications>
        <notification-email nil="true"/>
        <sales-terms-ref-id nil="true"/>
        <sales-tax-code-id type="integer" nil="true"/>
        <service-address-2 nil="true"/>
        <billing-address-2 nil="true"/>
        <last-job-completed-number type="integer" nil="true"/>
        <last-job-completed-at type="dateTime" nil="true"/>
        <last-job-completed-name nil="true"/>
        <place-id>ChIJzx8Tm2V82YcRIYDk1Ult-Qc</place-id>
        <place-id-retrieved-at type="dateTime">2024-01-15T14:41:00-05:00</place-id-retrieved-at>
        <custom-fields-migrated type="boolean" nil="true"/>
        <opt-out-reason>Invalid number</opt-out-reason>
        <email-bounced type="boolean" nil="true"/>
        <street-latitude type="float" nil="true"/>
        <street-longitude type="float" nil="true"/>
        <stripe-payment-method-id nil="true"/>
        <children type="array"/>
    </customer>
</customers>`;

    parseCustomerData(customersXML);
  }, []);

  const filteredCustomers = customers.filter((customer) => {
    const values = Object.values(customer);
    return values.some(
      (value) => value && value.toLowerCase().includes(filter.toLowerCase())
    );
  });

  const startIndex = currentPage * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedCustomers = filteredCustomers.slice(startIndex, endIndex);

  return (
    <div style={{ width: "100%", padding: "2px 25px" }}>
      <h1>Customer List</h1>
      <input
        type="text"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Search all columns..."
        style={{ marginBottom: "10px", padding: "5px" }}
      />

      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ border: "1px solid black", padding: "5px" }}>
              Full Name
            </th>
            <th style={{ border: "1px solid black", padding: "5px" }}>
              Service Address
            </th>
            <th style={{ border: "1px solid black", padding: "5px" }}>
              Service City
            </th>
            <th style={{ border: "1px solid black", padding: "5px" }}>
              Service State
            </th>
          </tr>
        </thead>
        <tbody>
          {paginatedCustomers.map((customer, index) => (
            <tr key={index}>
              <td style={{ border: "1px solid black", padding: "5px" }}>
                {customer.fullName}
              </td>
              <td style={{ border: "1px solid black", padding: "5px" }}>
                {customer.serviceAddress}
              </td>
              <td style={{ border: "1px solid black", padding: "5px" }}>
                {customer.serviceCity}
              </td>
              <td style={{ border: "1px solid black", padding: "5px" }}>
                {customer.serviceState}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div>
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
          disabled={currentPage === 0}
        >
          Previous
        </button>
        <span> Page {currentPage + 1} </span>
        <button
          onClick={() =>
            setCurrentPage((prev) =>
              Math.min(
                prev + 1,
                Math.ceil(filteredCustomers.length / rowsPerPage) - 1
              )
            )
          }
          disabled={
            currentPage >= Math.ceil(filteredCustomers.length / rowsPerPage) - 1
          }
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default KickservPage;
